import { ChildrenProps } from '../../@types/Props';
import { AuthProvider } from '../../contexts/AuthenticationContext';
import { CommandProvider } from '../../contexts/CommandContext';
import { DeviceProvider } from '../../contexts/DeviceContext';
import { GeofenceProvider } from '../../contexts/GeofenceContext';
import { GroupProvider } from '../../contexts/GroupContext';
import { NotificationProvider } from '../../contexts/NotificationContext';
import { PermissionProvider } from '../../contexts/PermissionContext';
import { PositionProvider } from '../../contexts/PositionContext';
import { ReportProvider } from '../../contexts/ReportContext';
import { SnackbarProvider } from '../../contexts/SnackbarContext';
import { TranslationProvider } from '../../contexts/TranslationContext';
import { UserProvider } from '../../contexts/UserContext';

const ContextContainer = ({children}: ChildrenProps) => {
  return (
    <SnackbarProvider>
      <TranslationProvider>
        <AuthProvider>
          <GroupProvider>
              <DeviceProvider>
                <UserProvider>
                  <NotificationProvider>
                    <PermissionProvider>
                      <CommandProvider>
                        <ReportProvider>
                          <GeofenceProvider>
                            <PositionProvider>
                              {children}
                            </PositionProvider>
                          </GeofenceProvider>
                        </ReportProvider>
                      </CommandProvider>
                    </PermissionProvider>
                  </NotificationProvider>
                </UserProvider>
              </DeviceProvider>
          </GroupProvider>
        </AuthProvider>
      </TranslationProvider>
    </SnackbarProvider>
  );
};

export default ContextContainer;