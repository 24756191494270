import { fetchPositions, removePositions } from "./position";
import { device } from "./device";
import { session } from "./session";
import { fetchGroups } from "./group";
import { user } from "./user";
import { notification } from "./notification";
import { command } from "./command";
import { permission } from "./permission";
import { report } from "./report";
import { bill } from "./bill";
import { geofence } from  "./geofence";

export const api = {
  session,
  device,
  fetchPositions,
  removePositions,
  fetchGroups,
  user,
  notification,
  command,
  permission,
  report,
  bill,
  geofence,
}