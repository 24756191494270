import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { InputLabelProps, Stack } from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import Button from '../Common/Button';
import { useAuthContext } from '../../contexts/AuthenticationContext';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton'
import { useReportContext } from '../../contexts/ReportContext';
import { HoursOptionsType, ReportOptionsType, TableActionType } from '../../@types/Props';
import { RootState, reportActions } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { TextMaskMoneyReal } from '../Common/Masks';
import dayjs, { Dayjs } from 'dayjs';
import { useDeviceContext } from '../../contexts/DeviceContext';
import { dateToStringWithHour } from '../../util/util';
import { usePositionContext } from '../../contexts/PositionContext';
import AlertDialog from '../Common/AlertDialog';

const SearchReport = () => {
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const { fetchDevicesAndPositions } = useDeviceContext();
  const {
    reportType,
    setReportType,
    reportOptions,
    hoursOptions,
    fetchPositionsByDeviceId,
    fetchLastUpdateByUserId,
    fetchNoGpsDevices,
    fetchTotalGroupDevices,
    fetchGroupPayroll
  } = useReportContext();
  
  const {
    removePositions,
  } = usePositionContext();

  const positions =  useSelector((state: RootState) => state.report.positions);
  const devices = useSelector((state: RootState) => state.device.items);
  const deviceCount = Object.entries(devices).length;

  const deviceOptions = Object.entries(devices).map((value, index) => { 
    return {
      label: `${value[1].attributes.placa} - ${value[1].name} - (#${index})`,
      value: value[1].id
    }
  });

  const [hours, setHours] = useState<HoursOptionsType | null>(hoursOptions[0]);
  const [unitCost, setUnitCost] = useState<string>('2,50')
  const [beginDate, setBeginDate] = useState<Dayjs | null>(dayjs().subtract(3, 'hour'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [deviceId, setDeviceId] = useState<number | null | undefined>(null);

  const [showPositionDelete, setShowPositionDelete] = useState(false);

  const toggleShowPositionDelete = () => setShowPositionDelete(!showPositionDelete);

  const deletePosition = {
    action: (item: TableActionType) => {
      toggleShowPositionDelete()
    },
    close: () => {
      toggleShowPositionDelete();
    },
    onAgree: () => {
      removePositions(
        deviceId as number,
        dateToStringWithHour(beginDate?.toDate()),
        dateToStringWithHour(endDate?.toDate()),
        () => {
          fetchPositionsByDeviceId(deviceId as number, dateToStringWithHour(beginDate?.toDate()), dateToStringWithHour(endDate?.toDate()))
          toggleShowPositionDelete()
        })
    }
  };

  const handleRequest = () => {
    if (reportType?.value === 'lastUpdate') fetchLastUpdateByUserId(user.id, hours?.value as number);
    if (reportType?.value === 'noGps') fetchNoGpsDevices(user.id);
    if (reportType?.value === 'totalGroupDevices') fetchTotalGroupDevices(user.id);
    if (reportType?.value === 'groupPayroll') fetchGroupPayroll(user.id, parseFloat(unitCost.replaceAll(',', '.').replace('R', '').replace('$', '')));
    if (reportType?.value === 'positions') fetchPositionsByDeviceId(deviceId as number, dateToStringWithHour(beginDate?.toDate()), dateToStringWithHour(endDate?.toDate()))
  }

  const handleChange = (event: SyntheticEvent<Element, Event>, value: ReportOptionsType | null) => {
    dispatch(reportActions.reset());
    setReportType(value);
  }

  useEffect(() => {
    if (user === null)
      return;
    
    if (deviceCount === 0)
      fetchDevicesAndPositions(user.id);
  });

  useEffect(() => {
    setReportType(reportOptions[0]);
  }, []);
  
  return (
    <div className='m-4 h-auto w-80 select-none'>

      <Autocomplete
        className='pb-4'
        autoComplete
        includeInputInList
        id="report-autocomplete"
        isOptionEqualToValue={(option, value) => option.value === value.value && option.label === value.label}
        value={reportType}
        options={reportOptions}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...(params as AutocompleteRenderInputParams & { InputLabelProps: React.PropsWithChildren<InputLabelProps> })} label="Selecione o relatório" variant="outlined" />
        )}
      />

      {
        reportType?.value === 'lastUpdate' ?
        <Autocomplete
          className='pb-4'
          autoComplete
          includeInputInList
          id="hour-autocomplete"
          isOptionEqualToValue={(option, value) => option.value === value.value && option.label === value.label}
          value={hours}
          options={hoursOptions}
          onChange={(event, item) => setHours(item)}
          renderInput={(params) => (
            <TextField {...(params as AutocompleteRenderInputParams & { InputLabelProps: React.PropsWithChildren<InputLabelProps> })} label="Selecione o período sem atualização" variant="outlined" />
          )}
        /> :
        reportType?.value === 'groupPayroll' ?
        <div className='pb-4'>
          <TextField
            autoCapitalize='true'
            fullWidth
            variant='outlined'
            label="Custo unitário (R$)"
            id="unit-cost"
            name="unit-cost"
            value={unitCost}
            onChange={(event) => setUnitCost(event.target.value)}
            InputProps={{
              inputComponent: TextMaskMoneyReal as any
            }}
          />
        </div> :
        reportType?.value === 'positions' ?
        <div>
          {
            deviceCount === 0 ?
            <Skeleton containerClassName="flex h-16 w-full"/> :
            <Autocomplete
              // className={Object.entries(groups).length > 1 ? 'w-80' : 'hidden'}
              className='pb-4'
              autoComplete
              includeInputInList
              id="placa-autocomplete"
              isOptionEqualToValue={(option, value) => option.value === value.value && option.label === value.label}
              options={deviceOptions}
              onChange={(event, item) => setDeviceId(item !== undefined ? item?.value : null)}
              renderInput={(params) => (
                <TextField {...(params as AutocompleteRenderInputParams & { InputLabelProps: React.PropsWithChildren<InputLabelProps> })} label="Selecione a placa" variant="outlined" />
              )}
            />
          }
          <Stack spacing={3}>
            <DesktopDateTimePicker
              label='Selecione o dia e hora iniciais'
              format='DD/MM/YYYY HH:mm'
              ampm={false}
              value={beginDate}
              onChange={value => setBeginDate(value)}
            />
          </Stack>

          <div className='pt-4'></div>
          <Stack spacing={3}>
            <DesktopDateTimePicker
              label='Selecione o dia e hora finais'
              format='DD/MM/YYYY HH:mm'
              ampm={false}
              value={endDate}
              onChange={value => setEndDate(value)}
            />
          </Stack>

          <div className='pb-4'></div>
        </div> : null

      }
      
      {
        reportType?.value === 'lastUpdate' || reportType?.value === 'noGps' || 
        reportType?.value === 'totalGroupDevices' || reportType?.value === 'groupPayroll' ?
          <Button
            action={handleRequest}
            label='Buscar'
          />
        :
        reportType?.value === 'positions' ? 
        <div>
          <Button
            action={handleRequest}
            label='Buscar'
          />
          <div className='m-4' />
          <Button
            disabled={positions.length === 0}
            action={toggleShowPositionDelete}
            label='Excluir'
            isDeletion
          />
        </div>
         : null
      }
      
      <AlertDialog
        title={`Remover posições ${Object.entries(devices).find(x => x[1].id === deviceId)?.[1].attributes.placa}`}
        text={`Deseja remover as posições? Serão removidas ${positions.length} posições. Essa ação é irreversível.`}
        opened={showPositionDelete}
        onAgree={deletePosition.onAgree}
        onDisagree={deletePosition.close}
        onClose={deletePosition.close}
      />
    </div>
  );
};

export default SearchReport;