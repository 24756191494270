import L from "leaflet";
import { Device } from "../@types/Device";

export const getTimeDiff = (date: string | null, onlyHours = false) => {
  if (date === null)
    return "Sem dados da última comunicação";
  
  const lasUpdate = new Date(date);

  const diffTime = Math.abs(Date.now().valueOf() - lasUpdate.valueOf());
  const diffSeconds = Math.floor(diffTime / (1000));
  const diffMinutes = Math.floor(diffTime / (1000 * 60));
  const diffHours = Math.floor(diffTime / (1000 * 60 * 60)); 
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  // console.log(diffTime + " milliseconds");
  // console.log(diffSeconds + " seconds");
  // console.log(diffMinutes + " minutes");
  // console.log(diffHours + " hours");
  // console.log(diffDays + " days");

  if (onlyHours) {
    if (diffDays > 0) {
      return diffDays === 1 ? `Comunicou há ${diffHours} horas (${diffDays} dia)` : `Comunicou há ${diffHours} horas (${diffDays} dias)`;
    }
    else if (diffHours > 0) {
      return diffHours === 1 ? `Comunicou há ${diffHours} hora` : `Comunicou há ${diffHours} horas`;
    }
    else if (diffMinutes > 0) {
      return `Comunicou há 1 hora ou menos (${diffMinutes} minutos)`;
    }
    else if (diffSeconds > 0) {
      return "Comunicou há 1 hora ou menos (alguns segundos atrás)";
    }
  }
  else {
    if (diffDays > 0) {
      return diffDays === 1 ? `Comunicou há ${diffDays} dia` : `Comunicou há ${diffDays} dias`;
    }
    else if (diffHours > 0) {
      return diffHours === 1 ? `Comunicou há ${diffHours} hora` : `Comunicou há ${diffHours} horas`;
    }
    else if (diffMinutes > 0) {
      return diffMinutes === 1 ? `Comunicou há ${diffMinutes} minuto` : `Comunicou há ${diffMinutes} minutos`;
    }
    else if (diffSeconds > 0) {
      return "Comunicou há poucos segundos";
    }
  }
}

export const formatDate = (date: string) => {
  let dt = new Date(date);
  let data = dt.toLocaleString('pt-BR').replace(" ", " às ");
  //31/12/2020 17:35:49 => 31/12/2020 Hora: 17:35:49

  return `${data}`;
}

const speedConverter = (unit: string) => {
  switch (unit) {
    case 'kmh':
      return 1.852;
    case 'mph':
      return 1.15078;
    case 'kn':
    default:
      return 1;
  }
};

// export const speedUnitString = (unit: string, t) => {
//   switch (unit) {
//     case 'kmh':
//       return t('sharedKmh');
//     case 'mph':
//       return t('sharedMph');
//     case 'kn':
//     default:
//       return t('sharedKn');
//   }
// };

export const meterToKm = (value?: number) => {
  if (value === null || value === undefined)
    return value;

  return `${(value/1000).toFixed(2)}`//.toString().replace(".", ",")}`
};

export const formatDistance = (value?: number) => {
  if (value === null || value === undefined)
    return value;

  return value > 1000 ?
  `${(value/1000).toFixed(2).toString().replace(".", ",")} km`
  :
  `${value.toFixed(2).toString().replace(".", ",")} m`;
};

export const formatTime = (milliseconds?: number) => {
  if (milliseconds === null || milliseconds === undefined)
    return milliseconds;

  let hours = milliseconds / (3.6 * 10 ** 6);
  let minutes = Math.round(milliseconds / 60000);
  if (hours > 1) {
    let minutesLeft = ((hours - Math.floor(hours)) * 60).toFixed(0)
    return `${hours.toFixed(0).toString()} h ${minutesLeft} min`

  }
  else
    return `${minutes} min`
}

export const speedToKnots = (value: number | undefined, unit: string) => 
  value !== undefined ? (value / speedConverter(unit)).toFixed(0) : 0;

export const speedFromKnots = (value: number | undefined, unit: string) => 
  value !== undefined ? (value * speedConverter(unit)).toFixed(0) : 0;

export const dateToStringWithHour = (date: Date | undefined) => {
  if (date === null || date === undefined)
    return '';
  
  //console.log(date);
  let dia = date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate();
  let mes = (date.getUTCMonth() + 1) < 10 ? "0" + (date.getUTCMonth() + 1) : (date.getUTCMonth() + 1);
  let ano = date.getUTCFullYear();
  let hora = date.getUTCHours();
  let minuto = date.getUTCMinutes();
  let segundo = date.getUTCSeconds();
  //1963-11-22T18:30:00Z
  return `${ano}-${mes}-${dia}T${hora < 10 ? `0${hora}` : hora}:${minuto < 10 ? `0${minuto}` : minuto}:${segundo < 10 ? `0${segundo}` : segundo}Z`;
};

export const sortName = (a: Device, b: Device) => {
  if (a === undefined || b === undefined)
    return 0;
  
  if (a.name < b.name)
    return -1;
  if (a.name > b.name)
    return 1;
  
  return 0;
};

export const sortPlaca = (a: Device, b: Device) => {
  if (a === undefined || b === undefined)
    return 0;
  
  if (a.attributes.placa < b.attributes.placa)
    return -1;
  if (a.attributes.placa > b.attributes.placa)
    return 1;
  
  return 0;
};

export const getIcon = (category: string) => {

  switch (category) {
    case 'animal':
    case 'bicycle':
    case 'boat':
    case 'bus':
    case 'car':
      return L.icon({
        iconUrl: require(`../assets/icons/markers/top/0.webp`),
        iconSize: [35,35],
        iconAnchor: [16.5,16.5],
        popupAnchor: [0,0],
      })
    case 'crane':
    case 'helicopter':
    case 'motorcycle':
      return L.icon({
        iconUrl: require(`../assets/icons/markers/top/motorcycle.png`),
        iconSize: [22,32],
        iconAnchor: [11,16],
        popupAnchor: [0,0],
      })
    case 'offroad':
    case 'person':
    case 'pickup':
      return L.icon({
        iconUrl: require(`../assets/icons/markers/top/pickup.png`),
        iconSize: [40,28],
        iconAnchor: [16,16],
        popupAnchor: [0,0],
      })
    case 'plane':
    case 'scooter':
    case 'tractor':
    case 'train':
    case 'tram':
    case 'trolleybus':
    case 'truck':
      return L.icon({
        iconUrl: require(`../assets/icons/markers/top/truck.png`),
        iconSize: [15,45],
        iconAnchor: [7.5,22.5],
        popupAnchor: [0,0],
      })
    case 'van':
      return L.icon({
        iconUrl: require(`../assets/icons/markers/top/van.png`),
        iconSize: [15,25],
        iconAnchor: [7.5,12.5],
        popupAnchor: [0,0],
      })
    default:
      return L.icon({
        iconUrl: require(`../assets/icons/markers/top/0.webp`),
        iconSize: [35,35],
        iconAnchor: [16.5,16.5],
        popupAnchor: [0,0],
      })
  }
}

export const convertToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const getGeofenceLatitude = (geofenceArea: string) => {
  if (geofenceArea.split(' ')[0] !== "CIRCLE")
    return 0.0;

  let latitude = geofenceArea.split(' ')[1].replace('(','');
  return parseFloat(latitude);
}

export const getGeofenceLongitude = (geofenceArea: string) => {
  if (geofenceArea.split(' ')[0] !== "CIRCLE")
    return 0.0;

  let longitude = geofenceArea.split(' ')[2].replace(',','');
  return parseFloat(longitude);
}

export const getGeofenceRadius = (geofenceArea: string) => {
  if (geofenceArea.split(' ')[0] !== "CIRCLE")
    return 0;

  let radius = geofenceArea.split(' ')[3].replace(')','');
  return parseFloat(radius);
}