import { createContext, useContext } from "react";
import { PositionContextType, ChildrenProps } from "../@types/Props";
import { api } from "../api/api";
import { ResponseContentType } from "../@types/Responses";
import { useSnackbarContext } from "./SnackbarContext";

const PositionContext = createContext<PositionContextType | null>(null);

export const PositionProvider = ({children} : ChildrenProps) => {

  const { sendRequest } = useSnackbarContext();

  const fetchPositions = () => {
    api.fetchPositions()
    .then(response => {
    })
  }

  const removePositions = (deviceId: number, from: string, to: string, callback?: () => void) => {
    const request = async () => await api.removePositions(deviceId, from, to);
    const onSuccess = (content: ResponseContentType) => {
      if (callback) callback();
    }

    sendRequest(request, onSuccess, "Posições removidas com sucesso.");
  }

  return (
    <PositionContext.Provider
      value={{
        fetchPositions,
        removePositions,
      }}
    >
      {children}
    </PositionContext.Provider>
    );
}

export const usePositionContext = () => {
  const context = useContext(PositionContext);
  if (!context) {
    throw new Error(
      'usePositionContext must be used within a PositionProvider'
    );
  }
  return context;
}