import { ButtonProps } from "../../@types/Props";


const Button = ({action, label, disabled, isDeletion}: ButtonProps) => {
  return (
    <button
      disabled={disabled ?? false}
      className={`h-10 w-full rounded ${disabled ? 'bg-gray-light' : isDeletion ? 'bg-delete hover:bg-delete-hover hover:cursor-pointer' : 'bg-dark-blue hover:bg-dark-blue-hover hover:cursor-pointer'}`}
      onClick={() => action()}
    >
      <p className='text-white text-center font-roboto font-bold'>
        {label}
      </p>
    </button>
  );
};

export default Button;