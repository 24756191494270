import { BaseResponseType, FetchPositionsResponseType } from "../@types/Responses";
import { deleteOptions, getOptions } from "./common";

const endpoint = '/api/positions';

export const fetchPositions = async () : Promise<FetchPositionsResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`, getOptions());

  return response.json();
}

export const removePositions = async (deviceId: number, from: string, to: string) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}?deviceId=${deviceId}&from=${from}&to=${to}`, deleteOptions());
  return response.json();
}